import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { useCountUp } from "react-countup";
import styled from "styled-components";
import Layout from "../components/layout";
import SwiperComponent from "../components/swiper";
import { Link, Trans } from "gatsby-plugin-react-i18next";
import {
  APICallsIcon,
  CaretRightIcon,
  PaymentProcessedIcon,
  SecureTransactionsIcon,
  ArrowRightIcon,
  ArrowRightShortIcon,
} from "../images/Icons";

import HeroImage from "../images/home/hero.png";
import HeroVideo from "../images/home/hero.mp4";
import AwardIcon from "../images/home/award-badge.png";

import PartnerImage from "../images/home/partners.png"
import DreamMakersImage from "../images/home/moniemakers.png"

const IndexPage = ({ data }) => {
  useCountUp({
    ref: "counter",
    start: 600000,
    end: 1000000,
    enableScrollSpy: true,
    scrollSpyDelay: 1000,
    separator: ",",
  });

  // const News = [
  //   {
  //     publisher: "Benjamindada.com",
  //     logo: "https://www.benjamindada.com/content/images/size/w256h256/2023/01/BD-2.0-Favicon.png",
  //     headline:
  //       "TeamApt's evolution from a tech provider to a startup doing over $14 billion TPV in a month",
  //     link: "https://www.benjamindada.com/teamapt-rebrand-moniepoint-14-billion-tpv-month/",
  //   },
  //   {
  //     publisher: "Business Insider Africa",
  //     logo: "https://ocdn.eu/radp/biafrica/favicon.ico",
  //     headline:
  //       "Exclusive: TeamApt rebrands as Moniepoint to empower more African small and medium enterprises to grow",
  //     link: "https://africa.businessinsider.com/local/markets/teamapt-rebrands-as-moniepoint-to-empower-more-african-small-and-medium-enterprises/rgdcd51",
  //   },
  //   {
  //     publisher: "PYMNTS",
  //     logo: "https://securecdn.pymnts.com/wp-content/uploads/2022/08/favicon-32x32-1.png",
  //     headline:
  //       "TeamApt Rebrands as Moniepoint and Relocates to London",
  //     link: "https://www.pymnts.com/news/digital-banking/2023/teamapt-rebrands-as-moniepoint-and-relocates-to-london/",
  //   },
  //   {
  //     publisher: "TechCrunch",
  //     logo: "https://techcrunch.com/wp-content/uploads/2015/02/cropped-cropped-favicon-gradient.png?w=180",
  //     headline:
  //       "QED makes its first African investment, backing Nigerian fintech TeamApt in $50M+ deal",
  //     link: "https://techcrunch.com/2022/08/10/fintech-vc-qed-makes-its-first-african-investment-backing-nigerias-teamapt-in-50m-deal/?guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAAKjbfr5-9dEoWrXgYuLmCF0mX75El1VY6YwCTWnzFW9AsclUcCD6BO90q4xpmZR_kbD_0AJTY_wjl5n3eXwCWCgsaVMqMW71rhW9OIkepTm8Jj5GCSvP0HPQGX-7qc-d-t6uXIIruyxrUkjQaxskmN8NrmtqEa7aCI6t3z6Hs9E6&guccounter=2",
  //   },
  //   {
  //     publisher: "The Africa Report",
  //     logo: "https://drive.google.com/uc?export=view&id=1sP58nIfBBDAKmop-oaaGczpQLa2cqkuO",
  //     headline: "Africa’s tech start-up scene is maturing,” says TeamApt CEO",
  //     link: "https://www.theafricareport.com/245399/africas-tech-start-up-scene-is-maturing-says-teamapt-ceo/",
  //   },
  //   {
  //     publisher: "ThisDay",
  //     logo: "https://www.thisdaylive.com/wp-content/themes/thisdaylive/library/favicons/apple-icon-57x57.png",
  //     headline: "TeamApt Diversifies into Full Banking Services with Moniepoint App",
  //     link: "https://www.thisdaylive.com/index.php/2022/04/14/teamapt-diversifies-into-full-banking-services-with-moniepoint-app/",
  //   },
  //   {
  //     publisher: "TechCrunch",
  //     logo: "https://techcrunch.com/wp-content/uploads/2015/02/cropped-cropped-favicon-gradient.png?w=180",
  //     headline:
  //       "TeamApt will use its new funding round to provide digital bank services for the unbanked",
  //     link: "https://techcrunch.com/2021/07/01/teamapt-will-use-its-new-funding-round-to-provide-digital-bank-services-for-the-unbanked/",
  //   },
  //   {
  //     publisher: "The Fintech Times",
  //     logo: "https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/pxzuyqi05k4lztfam54f",
  //     headline:
  //       "Championing Women in Fintech with TeamApt, Envestnet, Adsum, ForwardAI and Moneyhub",
  //     link: "https://thefintechtimes.com/championing-women-in-fintech-with-teamapt-envestnet-adsum-forwardai-and-moneyhub/",
  //   },
  // ];

  const News = data.news.nodes;
  console.log(data)


  return (
    <Layout pageTitle="Moniepoint Inc." type={"mobile_transparent"} description="Powering the dreams of businesses in emerging markets">
      <HeroContainer mdSrc={HeroImage}>
        <video autoPlay loop className="hero-video" muted playsInline onContextMenu={() => { return false; }} preload="auto" >
          <source src={HeroVideo} type="video/mp4" />
          Your browser does not support HTML5 video.
        </video>
        <div className="hero-mask"></div>
        <div className="hero-content-wrapper">
          <div className="hero-content">
            <a href="https://www.ft.com/africas-fastest-growing-companies-2023" target="_blank" className="award-badge">
              <img src={AwardIcon} height="27" className="award-badge-image" />
              <span>
                <Trans>homepage.award_text</Trans>
              </span>
            </a>

            <h1 className="hero-title">
              <Trans>homepage.hero_title</Trans>
            </h1>
            <p className="hero-subtitle">
              <Trans>homepage.hero_subtitle</Trans>
            </p>
            <Link to="#join-us" className="btn btn-primary">
              <Trans>homepage.hero_cta</Trans>
              <ArrowRightShortIcon size={24} />
            </Link>
          </div>
        </div>
      </HeroContainer>
      <BusinessContainer id="products">
        <div className="content">
          <div className="top-div">
            <h2 className="title">
              <Trans>homepage.solutions_title</Trans>
            </h2>
            <p className="description">
              <Trans>homepage.solutions_description</Trans>
            </p>

            <div className="solutions-container">
              <div className="solution-card grow">
                <StaticImage
                  loading="eager"
                  alt="..."
                  src="../images/payments/hero.png"
                  className="solution-img payments"
                />
                <div className="title">
                  <Trans>payments</Trans>
                </div>
                <div className="description">
                  <Trans>homepage.solutions_payments</Trans>
                </div>
                <Link className="link" to="/payments">
                  <span>
                    <Trans>learn_more</Trans>
                  </span>
                  <span className="icon">
                    <CaretRightIcon size={20} />
                  </span>
                </Link>
              </div>
              <div className="solution-card grow">
                <StaticImage
                  loading="eager"
                  alt="..."
                  src="../images/credit/hero.png"
                  className="solution-img credit"
                />
                <div className="title">
                  <Trans>credit</Trans>
                </div>
                <div className="description">
                  <Trans>homepage.solutions_credit</Trans>
                </div>
                <Link className="link" to="/credit">
                  <span>
                    <Trans>learn_more</Trans>
                  </span>
                  <span className="icon">
                    <CaretRightIcon size={20} />
                  </span>
                </Link>
              </div>
              <div className="solution-card grow">
                <StaticImage
                  loading="eager"
                  alt="..."
                  src="../images/business-management/hero.png"
                  className="solution-img business-management"
                />
                <div className="title">
                  <Trans>business_management</Trans>
                </div>
                <div className="description">
                  <Trans>homepage.solutions_business_management</Trans>
                </div>
                <Link className="link" to="/business-management">
                  <span>
                    <Trans>learn_more</Trans>
                  </span>
                  <span className="icon">
                    <CaretRightIcon size={20} />
                  </span>
                </Link>
              </div>
              <div className="solution-card grow">
                <StaticImage
                  loading="eager"
                  alt="..."
                  src="../images/banking/hero.png"
                  className="solution-img banking"
                />
                <div className="title">
                  <Trans>banking</Trans>
                </div>
                <div className="description">
                  <Trans>homepage.solutions_banking</Trans>
                </div>
                <Link className="link" to="/banking">
                  <span>
                    <Trans>learn_more</Trans>
                  </span>
                  <span className="icon">
                    <CaretRightIcon size={20} />
                  </span>
                </Link>
              </div>
            </div>
          </div>

          <div className="stats-section">
            <h2 className="title stats">
              <span>
                <Trans>trusted_by</Trans>{" "}
              </span>
              <span id="counter"></span>
              <span>+ </span>
              <span>
                <Trans>businesses</Trans>
              </span>
            </h2>
            <p className="description">
              <Trans>homepage.business_stats_description</Trans>
            </p>

            <div className="stats-container">
              <div className="stat-items">
                <div className="stat-item">
                  <APICallsIcon />
                  <div>
                    <h4 className="value">
                      200 <Trans>million</Trans>
                    </h4>
                    <p className="label">
                      <Trans>homepage.api_calls_per_minute</Trans>
                    </p>
                  </div>
                </div>
                <div className="divider"></div>
                <div className="stat-item">
                  <PaymentProcessedIcon />
                  <div>
                    <h4 className="value">
                      17 <Trans>million</Trans>
                    </h4>
                    <p className="label">
                      <Trans>homepage.payments_processed_daily</Trans>
                    </p>
                  </div>
                </div>
                <div className="divider"></div>
                <div className="stat-item">
                  <SecureTransactionsIcon />
                  <div>
                    <h4 className="value">
                      $14 <Trans>billion</Trans>
                    </h4>
                    <p className="label">
                      <Trans>homepage.secure_transactions</Trans>
                    </p>
                  </div>
                </div>
              </div>
              <iframe
                className="stats-video"
                width="100%"
                src="https://www.youtube.com/embed/wgzIP2Yci7E"
                title="Moniepoint Q2 Milestone; Launched Working Capital Loans Product and Employee Perks Update."
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                // allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </BusinessContainer>
      <InsideMoniepointContainer>
        <div className="content">
          <div className="top-container">
            <h2 className="title">
              <Trans>Inside Moniepoint</Trans>
            </h2>
            <p className="description">
              <Trans>homepage.inside_moniepoint_description</Trans>
            </p>
            <div className="swiper-section">
              <SwiperComponent
                loop
                slidesPerView={"auto"}
                width={330}
                autoplay
                breakpoints={{
                  768: {
                    slidesPerView: 1,
                    spaceBetween: 32,
                    width: 350,
                  },
                }}
              >
                <Link to="/blog/meet-pawel-our-new-coo-capital-one-veteran-and-hobbyist-pilot" className="inside_swipe-card grow">
                  <StaticImage
                    loading="eager"
                    alt="..."
                    src="../images/home/p6.png"
                    className="card-img"
                  />
                  <div className="card-title">
                    Meet Pawel, our new COO; Capital One veteran and hobbyist pilot.
                  </div>
                  <div className="card-description">Tosin Eniolorunda</div>
                </Link>
                <Link to="/blog/going-beyond-cards-the-genesis-of-pay-via-bank-transfer" className="inside_swipe-card grow">
                  <StaticImage
                    loading="eager"
                    alt="..."
                    src="../images/home/p1.png"
                    className="card-img"
                  />
                  <div className="card-title">
                    Going Beyond Cards; The Genesis of "Pay via Bank Transfer"
                  </div>
                  <div className="card-description">Ifeanyi Duru</div>
                </Link>
                <Link to="/blog/product-development-and-the-customer-success-piece-of-the-puzzle" className="inside_swipe-card grow">
                  <StaticImage
                    loading="eager"
                    alt="..."
                    src="../images/home/p4.jpg"
                    className="card-img"
                  />
                  <div className="card-title">
                    Product development and the Customer Success piece of the puzzle
                  </div>
                  <div className="card-description">Demilade Egbowon</div>
                </Link>
                <Link to="/blog/wisdom-ndebe-on-how-design-impacts-our-experiences" className="inside_swipe-card grow">
                  <StaticImage
                    loading="eager"
                    alt="..."
                    src="../images/home/p3.jpg"
                    className="card-img"
                  />
                  <div className="card-title">
                    Ripples: Wisdom Ndebe on How Design Impacts Our Experiences
                  </div>
                  <div className="card-description">Wisdom Ndebe</div>
                </Link>
                <Link to="/blog/how-isoken-explores-product-development-through-sales" className="inside_swipe-card grow">
                  <StaticImage
                    loading="eager"
                    alt="..."
                    src="https://drive.google.com/uc?export=view&id=1kjKzoPUN0_EanCXhK278CEpEztmDOooJ"
                    className="card-img"
                  />
                  <div className="card-title">
                    Ripples: How Isoken Explores Product Development Through Sales
                  </div>
                  <div className="card-description">Isoken Aigbomian</div>
                </Link>
                <Link to="/blog/how-moniepoint-became-king" className="inside_swipe-card grow">
                  <StaticImage
                    loading="eager"
                    alt="..."
                    src="../images/home/p2.png"
                    className="card-img"
                  />
                  <div className="card-title">How Moniepoint Became King</div>
                  <div className="card-description">Tobi Amira</div>
                </Link>
              </SwiperComponent>
            </div>
          </div>

          <div className="join-container" id="join-us">
            <h2 className="title">
              <Trans>homepage.joinus_title</Trans>
            </h2>
            <div className="join-section">
              <ImageCard className="grow" bg={DreamMakersImage}>

                <div className="card-mask"></div>
                <div className="card-content">
                  <div className="card-text">
                    <h4 className="card-title">
                      <Trans>homepage.joinus_moniemakers</Trans>
                    </h4>
                    <p className="card-body">
                      <Trans>homepage.joinus_moniemakers_description</Trans>
                    </p>
                  </div>
                  <Link to="/inside-moniepoint#roles" className="card-action">
                    <ArrowRightIcon />
                  </Link>
                </div>
              </ImageCard>

              <ImageCard className="grow" bg={PartnerImage}>

                <div className="card-mask"></div>
                <div className="card-content">
                  <div className="card-text">
                    <h4 className="card-title">
                      <Trans>homepage.joinus_partners</Trans>
                    </h4>
                    <p className="card-body bottom">
                      <Trans>homepage.joinus_partners_description</Trans>
                    </p>
                  </div>
                  <a
                    href="mailto:partner@moniepoint.com"
                    className="card-action"
                  >
                    <ArrowRightIcon />
                  </a>
                </div>
              </ImageCard>
            </div>
          </div>
        </div>
      </InsideMoniepointContainer>
      <NewsRoomContainer>
        <div className="content">
          <div className="newsroom-container">
            <h2 className="title">
              <Trans>homepage.from_the_newsroom</Trans>
            </h2>
            <div className="swiper-section">
              <SwiperComponent
                loop={true}
                width={273}
                autoplay
                // useWidth={false}
                slidesPerView={"auto"}
              >
                {News.map((news, i) => (
                  <div className="newsroom_swipe-card" key={i}>
                    <div className="card-header">
                      <img
                        alt="stats video"
                        src={news?.newsPublisher?.publisherLogo?.url}
                        className="card-icon"
                      />
                      <div className="card-title">{news?.newsPublisher?.publisherName}</div>
                    </div>
                    <div className="card-description">{news?.headline}</div>
                    <a className="card-link" target="_blank" href={news?.linkToArticle}>
                      <span>
                        <Trans>read_more</Trans>
                      </span>
                      <ArrowRightShortIcon size={24} />
                    </a>
                  </div>
                ))}
              </SwiperComponent>
            </div>
          </div>
        </div>
      </NewsRoomContainer>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
   query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    news: allGraphCmsNewsMention(sort: {order: DESC, fields: publishedAt},   limit: 10) {
    nodes {
      linkToArticle
      newsPublisher {
        publisherName
        publisherLogo {
          url
        }
      }
      headline
    }
  }
  }
`;

const HeroContainer = styled.section`
  background: url(${(props) => props.mdSrc});
  background-size: cover;
  background-position: right center;
  background-repeat: no-repeat;
  min-height: 596px;
  position: relative;

  .hero-video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top;
  }

  .hero-mask {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    backdrop-filter: blur(1px);
    z-index: 1;
  }

  .hero-content-wrapper {
    height: 100%;
    max-width: 1440px;
    padding: 113px 149px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: end;
    z-index: 2;
    position: relative;
    @media only screen and (max-width: 1024px) {
      padding: 140px 60px;
    }
  }

  .hero-content {
    color: white;

    .award-badge {
      display: flex;
      padding: 4px 27px 4px 16px;
      border-radius: 50px;
      background: white;
      color: #0361f0;
      gap: 4px;
      align-items: center;
      width: fit-content;
      margin-bottom: 8px;
      text-decoration: none;

      .award-badge-image {
        width: 32px;
        height: 32px;
      }

      span {
        font-family: "Founders Grotesk";
        font-size: 16px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
      }
    }

    .hero-title {
      font-family: "Founders Grotesk";
      font-size: 58px;
      font-weight: 500;
      line-height: 66px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 16px;
      max-width: 800px;
    }

    .hero-subtitle {
      font-size: 17px;
      font-weight: 400;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: left;
      max-width: 476px;
      margin-bottom: 24px;
    }
  }

  @media only screen and (max-width: 768px) {

    height: 100vh;
    max-height: 539px;
    background-position: 38%;

    .hero-video {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: 38%;
    }

    .hero-mask {
      background: rgba(29, 29, 29, 0.7);
      backdrop-filter: blur(1px);
    }

    .hero-content-wrapper {
      padding: 47px 20px;
    }

    .hero-content {
      .hero-title {
        font-size: 40px;
        line-height: 44px;
      }
      .hero-subtitle {
        font-size: 16px;
        line-height: 25.76px;
      }
    }
  }
`;

const BusinessContainer = styled.section`
  background-color: white;

  .content {
    max-width: 1440px;
    margin: auto;
    padding: 42px 150px;
    @media only screen and (max-width: 1024px) {
      padding: 42px 60px;
    }

    .title {
      font-family: "Founders Grotesk";
      font-size: 52px;
      font-weight: 500;
      line-height: 61px;
      letter-spacing: 0em;
      margin-bottom: 16px;
      max-width: 758px;
      text-align: left;
    }

    .description {
      font-size: 17px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      max-width: 720px;
      margin-bottom: 16px;
      text-align: left;
    }

    .top-div {
      margin-bottom: 84px;
    }

    .solutions-container {
      margin-top: 52px;
      display: grid;
      overflow: visible;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: 1fr;
      grid-column-gap: 34px;
      grid-row-gap: 34px;
      padding-bottom: 20px;

      @media screen and (max-width: 1300px){
        grid-template-columns: repeat(2, 1fr);

        .solution-card {
          width: 100% !important;
        }
      }

      /* width */
      ::-webkit-scrollbar {
        width: 1px;
        height: 0px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      .solution-card {
        height: 100%;
        width: 261.16px;
        display: flex;
        flex-direction: column;

        .solution-img {
          height: 201px;
          width: 100%;
          border-radius: 8px;
          margin-bottom: 12px;

       
          &.credit,
          &.business-management {
            img {
              object-position: left;
            }
          }
          &.payments,
          &.banking {
            img {
              object-position: right;
            }
          }
        }

        .title {
          font-family: "Founders Grotesk";
          font-size: 24px;
          font-weight: 500;
          line-height: 28px;
          letter-spacing: 0em;
          text-align: left;
          margin-bottom: 8px;
        }
        .description {
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0em;
          text-align: left;
          margin-bottom: 12px;
          color: rgba(0, 0, 0, 0.7);
        }

        .link {
          margin-top: auto;
          display: flex;
          align-items: center;
          text-decoration: none;
          gap: 8px;
          width: fit-content;
          span {
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: center;
            color: rgba(3, 97, 240, 1);
          }

          .icon {
            display: none;
          }
        }
      }
    }

    .stats-section {
      .title {
        font-family: "Founders Grotesk";
        font-size: 52px;
        font-weight: 500;
        line-height: 61px;
        letter-spacing: 0em;
        margin-bottom: 16px;
        max-width: 738px;
        text-align: left;
        max-width: 930px;
        margin: 0 auto 16px;
        text-align: center;

        span {
          font-family: "Founders Grotesk";
        }
      }

      .description {
        font-size: 17px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        max-width: 720px;
        margin: 0 auto 48px;
        text-align: center;
      }
    }

    .stats-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 70px;

      .stat-items {
        width: auto;
        display: flex;
        flex-direction: column;
        gap: 24px;

        .divider {
          height: 1px;
          width: 80px;
          background: rgba(11, 50, 117, 0.1);
        }

        .stat-item {
          width: 356px;
          display: flex;
          gap: 31px;
          align-items: center;

          .value {
            font-family: "Founders Grotesk";
            font-size: 32px;
            font-weight: 500;
            line-height: 38px;
            letter-spacing: 0em;
            text-align: left;
          }
          .label {
            font-size: 17px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0em;
            text-align: left;
          }
        }
      }

      .stats-video {
        border-radius: 22px;
        aspect-ratio: 16/9;
        width: 100%;
        max-width: 715px;

        .html5-video-player {
          background-color: white !important;
        }

        @supports not (aspect-ratio: 1) {
          height: 408px;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .content {
      padding: 32px 20px;
      .title,
      .stats-section .title {
        font-size: 32px;
        line-height: 38px;
        max-width: 100%;
        text-align: center;
      }
      .description,
      .stats-section .description {
        font-size: 14px;
        line-height: 23px;
        max-width: 100%;
        text-align: center;
      }
      .description {
        margin-bottom: 0px;
      }

      .top-div,
      .stats-section .description {
        margin-bottom: 32px;
      }

      .solutions-container {
        margin-top: 32px;
        grid-template-columns: repeat(1, 1fr);
        gap: 40px;

        .solution-card {
          width: 100%;

          .solution-img {
            height: 304px;
            margin-bottom: 16px;

            &.payments,
            &.credit,
            &.business-management {
              img {
                object-position: center;
              }
            }
          }

          .title {
            font-size: 28px;
            line-height: 33px;
          }

          .description {
            margin-bottom: 16px;
          }

          .link {
            margin-top: 0;

            .icon {
              display: block;
            }
          }
        }
      }
      .stats-container {
        flex-direction: column;
        gap: 32px;

        .stat-items {
          width: 100%;
          order: 2;

          .stat-item {
            width: 100%;
          }
        }

        .stats-video {
          aspect-ratio: 16/9;

          @supports not (aspect-ratio: 1) {
            height: 200px;
            width: 100%;
          }
        }
      }
    }
  }
`;

const InsideMoniepointContainer = styled.section`
  background-color: #0357ee08;

  .content {
    max-width: 1440px;
    margin: auto;
    padding: 42px 150px 84px;
    display: flex;
    flex-direction: column;
    gap: 84px;

    @media only screen and (max-width: 1024px) {
      padding: 42px 60px 84px;
    }

    .title {
      font-family: "Founders Grotesk";
      font-size: 52px;
      font-weight: 500;
      line-height: 61px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 16px;
      max-width: 851px;
    }
    .description {
      font-size: 17px;
      font-weight: 400;
      line-height: 20.57px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 16px;
    }
  }

  .join-section {
    display: flex;
    gap: 29px;
  }

  .inside_swipe-card {
    width: 358px;
    text-decoration: none;

    .card-img {
      width: 358px;
      height: 368px;
      border-radius: 8px;
      margin-bottom: 16px;
    }
    .card-title {
      font-family: "Founders Grotesk";
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 8px;
color: rgba(0, 0, 0, 1);
    }
    .card-description {
      font-family: "Founders Grotesk";
      font-size: 14px;
      font-weight: 500;
      line-height: 21.42px;
      letter-spacing: 0em;
      text-align: left;
color: rgba(0, 0, 0, 1);
    }
  }

  @media only screen and (max-width: 768px) {
    .content {
      padding: 32px 20px 64px;
      .title {
        font-size: 32px;
        line-height: 38px;
        max-width: 100%;
      }
      .description {
        font-size: 14px;
        line-height: 28px;
        max-width: 100%;
      }
    }
    .join-section {
      flex-direction: column;
    }
    .join-container,
    .top-container,
    .newsroom-container {
      /* margin-bottom: 72px; */
    }

    .swiper-section {
      margin-top: 24px;
    }

    .inside_swipe-card {
      width: 330px;

      .card-img {
        width: 330px;
        height: 339px;
        border-radius: 8px;
      }
      .card-title {
        font-size: 20px;
        line-height: 24px;
      }
      .card-description {
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
`;

const NewsRoomContainer = styled.section`
  background-color: rgba(3, 97, 240, 0.03);

  .content {
    max-width: 1440px;
    margin: auto;
    padding: 42px 150px 64px;
    display: flex;
    flex-direction: column;
    gap: 64px;

    @media only screen and (max-width: 1024px) {
      padding: 42px 60px 64px;
    }

    .title {
      font-family: "Founders Grotesk";
      font-size: 48px;
      font-weight: 500;
      line-height: 57px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 16px;
      max-width: 851px;
    }
  }

  .swiper-section {
    margin-top: 36px;

    

    .swiper-slide {
      height: auto;
      max-height: 273px;

      @media only screen and (max-width: 768px) {
  height: 100%;
  max-height: 329px !important;
  width: 323px !important;
}
    }
  }

  .newsroom_swipe-card {
    border-radius: 8px;
    background-color: white;
    padding: 32px 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 16px;
    height: 263px;
    max-height: 263px;
    border: 1px solid #0357ee0d;

    .card-header {
      display: flex;
      gap: 12px;
      align-items: center;
      .card-icon {
        width: 27px;
        height: 27px;
      }

      .card-title {
        font-family: "Founders Grotesk";
        font-size: 16px;
        font-weight: 500;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .card-description {
      font-family: "Founders Grotesk";
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: -0.005em;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      line-clamp: 4;
      -webkit-box-orient: vertical;
    }

    .card-link {
      display: flex;
      align-items: center;
      gap: 8px;
      text-decoration: none;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
      text-transform: capitalize;
      margin-top: auto;
    }
  }

  @media only screen and (max-width: 768px) {
    .content {
      padding: 32px 20px 64px;
      gap: 64px;

      .title {
        font-size: 32px;
        line-height: 38px;
        max-width: 100%;
      }
      .description {
        font-size: 14px;
        line-height: 28px;
        max-width: 100%;
      }
    }
    .swiper-section {
      margin-top: 24px;
    }


    .newsroom_swipe-card {
      width: 273px;
    }
    
    .inside_swipe-card {
      width: 330px;

      .card-img {
        width: 330px;
        height: 339px;
        border-radius: 8px;
      }
      .card-title {
        font-size: 20px;
        line-height: 24px;
      }
      .card-description {
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
`;

const ImageCard = styled.div`
  width: 100%;
  height: 582px;
  max-width: 555px;
  border-radius: 22px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 40px 50px;
  position: relative;
  background: url(${props => props.bg});
  background-size: cover;
  background-position: center;

  .card-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 8px;
  }

  .card-mask {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 1);
    opacity: 0.5;
  }

  .card-content {
    position: relative;
    z-index: 6;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: end;
    gap: 46px;
  }

  .card-text .card-title {
    font-family: "Founders Grotesk";
    font-size: 28px;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 22px;
  }

  .card-text .card-body {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0px;

    &.bottom {
      margin-bottom: 16px;
    }
  }

  .card-action {
    background-color: white;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    padding: 13.33px;
    align-items: center;
    transition: transform 0.3s ease-in-out;
  }

  @media only screen and (max-width: 768px) {
    padding: 25px 24px;
    height: 392px;

    .card-content {
      gap: 24px;
    }

    .card-text .card-title {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 16px;
    }

    .card-text .card-body {
      font-size: 14px;
      line-height: 17px;
    }

    .card-action {
      all: unset;

      svg {
        height: 24px;
        width: auto;

        path {
          fill: white;
        }
      }
    }
  }
`;
